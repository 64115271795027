
<!--设备运维：定期更换-->
<template>
  <div class="">
    <div class="page-container">
      <div class="section">
        <h2 class="h2Style">
          定期更换
        </h2>
        <!--搜索栏-->
        <div class="control-box1">
          <div class="cb-left">
          </div>

          <div class="cb-right">
            <el-button
                type="primary"
                icon="el-icon-plus"
                @click="addTeamUserShow()"
            >
              添加
            </el-button>
          </div>
        </div>
        <!--表格-->
        <div class="table-box" style="width: 100%">
          <el-table
              v-loading="listLoading"
              :data="teamUser"
              element-loading-text="Loading"
              border
              highlight-current-row
              style="width: 100%"
          >
            <!--昵称-->
            <el-table-column label="设备名称" align="center" width="300">
              <template slot-scope="scope">
                {{ scope.row.name }}
              </template>
            </el-table-column>
            <el-table-column label="更换项目" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.project }}</span>
              </template>
            </el-table-column>
            <el-table-column label="备注" align="center" width="150">
              <template slot-scope="scope">
                <span>{{ scope.row.person }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="created_at" label="提报时间" align="center" width="170">
              <template slot-scope="scope">
                <span>{{ scope.row.date }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="created_at" label="状态" align="center" width="150">
              <template slot-scope="scope">
                <span style="font-weight: bold;" :style="{color: scope.row.result === '通过' ? 'green' : 'grey' }">
                {{scope.row.result}}
              </span>
              </template>
            </el-table-column>
            <!--操作-->
            <el-table-column label="操作" align="center" width="170">
              <template slot-scope="scope">
                <el-button
                    type="primary"
                    size="mini"
                    @click="updateTeamUser(scope.row)"
                >
                  查看
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!--分页-->
        <div class="pagination-box">
          <el-pagination
              :page-sizes="[10, 20, 30, 40]"
              :page-size="form.count"
              :total="totalCount"
              :current-page="form.page"
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          />
        </div>
      </div>

      <!--弹出框：添加异常提报-->
      <el-dialog
          :visible.sync="TeamUserShow"
          title="添加定期更换"
          :close-on-click-modal="false"
          :modal-append-to-body="false"
          :append-to-body="false"
          width="30%"
          @close="close1()"
      >
        <el-form label-position="left" ref="inviteForm" label-width="110px" :model="invite" :rules="rules.inviteRules">
          <el-form-item label="设备名称" prop="deviceId">
            <el-select
                v-model="invite.deviceId"
                placeholder="请选择设备"
                style="width: 100%"
            >
              <el-option
                  v-for="item in deviceList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="更换项目" prop="project">
            <el-select
                v-model="invite.project"
                placeholder="请选择更换项目"
                style="width: 100%"
            >
              <el-option
                  v-for="item in reasonList"
                  :key="item"
                  :label="item"
                  :value="item"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input
                v-model="invite.remark"
                show-word-limit
                clearable
                placeholder="请填写备注"
                maxlength="100"
            />
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" plain @click="TeamUserShow = false">取 消</el-button>
          <el-button type="primary" :loading="addUserBtnLoading" @click="addTeamUser()">
            提交
          </el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import API from '@/api';
import valid from '@/utils/validator';
import { manageOauth } from '@/utils/validate';
import moment from "moment";
export default {
  name: 'replacement',
  data() {
    return {
      teamUser: [],
      TeamUserShow: false,
      addUserBtnLoading: false,
      invite: {
        deviceId: '',
        remark: '',
        project: ''
      },
      pickerOptions: {
        selectableRange: '00:00:00-23:59:59' // 限制可选择的时间范围
      },
      deviceList:[],
      reasonList: [],
      listLoading: false,
      // 查询
      form: {
        page: 1,
        count: 10,
        type: 1
      },
      rules: {
        inviteRules: {
          deviceId: [{ required: true, message: '请选择设备', trigger: 'change' }],
          project: [{ required: true, message: '请选择更换目录', trigger: 'change' }],
          remark: [{ required: true, trigger: 'blur', message: '请填写备注' }],
        },
      },
      totalCount: 0,
    };
  },
  created() {
    this.getTeamUser();
    this.getList()
  },
  mounted() {

  },
  methods: {
    getTeamUser() {
      API.getRecordList(this.form).then((response) => {
        this.teamUser = response.message.data.list;
        this.totalCount = response.message.data.totalCount;
      });
    },
    getList() {
      API.repairDeviceList().then(res => {
        this.deviceList.push(...res.message.data)
        console.log(this.deviceList)
      })
      API.reasonList({type:14}).then((res) => {
        console.log(res)
        this.reasonList.push(...res.message.data)
      })
    },
    dateFmt(timestamp) {
      return moment(timestamp).format('YYYY-MM-DD HH:mm')
    },
    // 查看
    updateTeamUser() {

    },
    addTeamUserShow() {
      this.invite = {
        deviceId: '',
        remark: '',
        project: ''
      }
      this.TeamUserShow = true;
      this.$nextTick(() => {
        // 清除验证
        this.$refs.inviteForm.clearValidate();
      });
    },
    close1() {
      this.TeamUserShow = false;
    },
    addTeamUser() {
      this.$refs.inviteForm.validate((v) => {
        if (v) {
          this.addUserBtnLoading = true;
          API.submitReplacement(this.invite)
              .then((response) => {
                // console.log(response.message.success)
                if (response.message.success) {
                  this.addUserBtnLoading = false;
                  this.$message({
                    message: response.message.message,
                    // showClose: true,
                    type: 'success',
                  });
                  this.TeamUserShow = false;
                  this.getTeamUser();
                } else if (!response.message.success) {
                  this.addUserBtnLoading = false;
                  // this.$message({
                  //   message: response.message.message,
                  //   // showClose: true,
                  //   type: 'error',
                  // });
                }
              })
              .catch(() => {
                this.addUserBtnLoading = false;
              });
        } else {
          return false;
        }
        return v;
      });
    },
    handleSizeChange(val) {
      this.form.count = val;
      this.form.page = 1;
      this.getTeamUser();
    },
    handleCurrentChange(val) {
      this.form.page = val;
      this.getTeamUser();
    },
  },
};
</script>

<style lang="scss" scoped>
//.page-container{
//  padding: 40px;
//}
//.section{
//  padding: 30px 40px;
//}
.h2Style {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #dcdfe6;
  font-size: 20px;
}
.PersonManagement {
  width: 100%;
}
.control-box1 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.cb-left {
  display: -webkit-box;
}
.control-item {
  margin-left: 15px;
}
::v-deep .form-item{
  display: inline-block;
}
</style>
